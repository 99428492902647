export default {
  data() {
    return {
      tenementLogin: "",
      tenementName: "",
      applicationName: [],
      createTime: "",
      state: "",
      remark: "",
    };
  },
  created() {
    const {
      tenementLogin,
      tenementName,
      applicationName,
      createTime,
      state,
      remark,
    } = this.$route.params;
    this.tenementLogin = tenementLogin;
    this.tenementName = tenementName;
    this.applicationName = applicationName.split(",");
    this.createTime = createTime;
    this.state = state;
    this.remark = remark;
  },
};
